
import {computed, defineComponent, onMounted, ref} from "vue";
import Entities from "@/components/base/common/Entities.vue";
import {setCurrentPageBreadcrumbsWithParams} from "@/core/helpers/breadcrumb";
import {LoadFilterObjects} from "@/core/composite/composite";
import {Actions} from "@/store/enums/StoreEnums";
import store from "@/store";
import QuickAction from "@/components/base/action/QuickAction.vue";
import KtDatatable from "@/components/kt-datatable/KTDatatable.vue";
import BaseModal from "@/components/base/modal/BaseModal.vue";
import TeamForm from "@/views/team/TeamForm.vue";
import Swal from "sweetalert2";
import GroupService from "@/core/services/GroupService";

export default defineComponent({
  name: "List",
  components: {TeamForm, BaseModal, KtDatatable, QuickAction, Entities},
  setup() {
    const state = ref({
      id: '',
      name: 'Add Team',
      model: {
        name: '',
        code: '',
        requestCompanyId: ''
      },
      submitting: false,
    })
    const filterObject = ref({});
    onMounted(() => {
      setCurrentPageBreadcrumbsWithParams("Teams",
        [
          {link: false, router: '', text: 'Teams'}
        ]
      )
    })
    const page = computed(() => store.state.GroupModule.page);
    const tableHeader = ref([
      {
        name: "Code",
        key: "code",
        sortable: true,
      },
      {
        name: "Name",
        key: "name",
        sortable: true,
      },
      {
        name: "Company",
        key: "company",
        sortable: true,
      },

      {
        name: "",
        key: "actions",
        sortable: true,
      },
    ]);
    return {
      state,
      tableHeader,
      page,
      filterObject,
      ...LoadFilterObjects(Actions.LOAD_GROUPS, filterObject.value, ['company'])
    }
  },
  methods: {
    onAdd() {
      this.state.id = ''
      this.state.model = {name: '', requestCompanyId: '', code: ''};
      this.state.name = 'Add Team'
      const modal = this.$refs.formTeamRef as typeof BaseModal;
      modal.showBaseModal();
    },
    onEdit(data) {
      this.state.id = data.id;
      this.state.name = 'Edit Team';
      this.state.model = {name: data.name, requestCompanyId: data.company.id, code: data.code};
      const modal = this.$refs.formTeamRef as typeof BaseModal;
      modal.showBaseModal();
    },
    onSaved() {
      const modal = this.$refs.formTeamRef as typeof BaseModal;
      modal.hideBaseModal();
      this.paginationLoad()
    },

    onDelete(id) {
      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!',
        showLoaderOnConfirm: true,
        heightAuto: false,
        preConfirm: async () => {
          await GroupService.delete(id).then(() => {
            this.paginationLoad();
          })
        }
      })
    }
  }
})
