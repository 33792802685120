
import {defineComponent, ref, watch} from "vue";
import BaseForm from "@/components/base/form/BaseForm.vue";
import * as Yup from "yup";
import {SelectAllClient} from "@/core/composite/composite";
import BaseSelect from "@/components/base/select/BaseSelect.vue";
import FormErrorMessage from "@/components/base/form/FormErrorMessage.vue";
import {Field} from 'vee-validate'
import GroupService from "@/core/services/GroupService";

export default defineComponent({
  name: "TeamForm",
  components: {FormErrorMessage, BaseSelect, BaseForm, Field},
  props: {
    id: {},
    team: {}
  },
  emits: ['saved'],
  setup(props) {
    const submitting = ref(false);
    const model = ref(props.team);
    watch(() => props.team, cb => {
      model.value = cb
    })
    const validate = Yup.object().shape({
      name: Yup.string().required().label("Name"),
      code: Yup.string().required().label("Code"),
      requestCompanyId: Yup.string().required().label("Company"),
    });

    return {
      validate,
      submitting,
      ...SelectAllClient(),
      model,
    }
  },
  methods: {
    onSubmit() {
      this.submitting = true;
      if (this.id) {
        GroupService.update(this.id, this.model).then(res=>{
          this.$emit('saved', res)
        }).finally(()=>{
          this.submitting = false;
        })
      }else  {
        GroupService.create(this.model).then(res=>{
          this.$emit('saved', res)
        }).finally(()=>{
          this.submitting = false;
        })
      }
    }
  }
})
