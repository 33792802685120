import { createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "menu-item px-3" }
const _hoisted_2 = { class: "card card-flush" }
const _hoisted_3 = {
  key: 0,
  class: "card-body pt-0"
}
const _hoisted_4 = { class: "menu-item px-3" }
const _hoisted_5 = ["onClick"]
const _hoisted_6 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_QuickAction = _resolveComponent("QuickAction")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_KtDatatable = _resolveComponent("KtDatatable")!
  const _component_Entities = _resolveComponent("Entities")!
  const _component_TeamForm = _resolveComponent("TeamForm")!
  const _component_base_modal = _resolveComponent("base-modal")!
  const _directive_is_admin = _resolveDirective("is-admin")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_Entities, {
      "enable-filter": false,
      "view-card": false,
      "view-table": true,
      label: "Teams",
      items: _ctx.page.total,
      "display-view": false
    }, {
      actions: _withCtx(() => [
        _createVNode(_component_QuickAction, null, {
          default: _withCtx(() => [
            _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
              _createElementVNode("a", {
                href: "javascript:void(0);",
                class: "menu-link px-3",
                onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onAdd && _ctx.onAdd(...args)))
              }, "Add Team")
            ])), [
              [_directive_is_admin]
            ])
          ]),
          _: 1
        })
      ]),
      table: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          (_ctx.page)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                _createVNode(_component_KtDatatable, {
                  "table-data": _ctx.page.data,
                  "table-header": _ctx.tableHeader,
                  "enable-items-per-page-dropdown": true,
                  "current-page": _ctx.filter.paginationPage,
                  "rows-per-page": _ctx.filter.paginationSize,
                  onItemsPerPageChange: _ctx.handleUpdateSize,
                  onCurrentChange: _ctx.handleUpdatePage,
                  total: _ctx.page.total
                }, {
                  "cell-code": _withCtx(({ row: data }) => [
                    _createVNode(_component_router_link, {
                      to: `/teams/`+ data.id + `/members`
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(data.code), 1)
                      ]),
                      _: 2
                    }, 1032, ["to"])
                  ]),
                  "cell-name": _withCtx(({ row: data }) => [
                    _createVNode(_component_router_link, {
                      to: `/teams/`+ data.id + `/members`
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(data.name), 1)
                      ]),
                      _: 2
                    }, 1032, ["to"])
                  ]),
                  "cell-company": _withCtx(({ row: data }) => [
                    _createTextVNode(_toDisplayString(data.company.name), 1)
                  ]),
                  "cell-actions": _withCtx(({row:data}) => [
                    _createVNode(_component_QuickAction, null, {
                      default: _withCtx(() => [
                        _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_4, [
                          _createElementVNode("a", {
                            href: "javascript:void(0);",
                            class: "menu-link px-3",
                            onClick: ($event: any) => (_ctx.onEdit(data))
                          }, "Edit", 8, _hoisted_5),
                          _createElementVNode("a", {
                            href: "javascript:void(0);",
                            class: "menu-link px-3 text-danger",
                            onClick: ($event: any) => (_ctx.onDelete(data.id))
                          }, "Delete", 8, _hoisted_6)
                        ])), [
                          [_directive_is_admin]
                        ])
                      ]),
                      _: 2
                    }, 1024)
                  ]),
                  _: 1
                }, 8, ["table-data", "table-header", "current-page", "rows-per-page", "onItemsPerPageChange", "onCurrentChange", "total"])
              ]))
            : _createCommentVNode("", true)
        ])
      ]),
      _: 1
    }, 8, ["items"]),
    _createVNode(_component_base_modal, {
      name: _ctx.state.name,
      id: "formTeam",
      ref: "formTeamRef"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_TeamForm, {
          id: _ctx.state.id,
          team: _ctx.state.model,
          onSaved: _ctx.onSaved
        }, null, 8, ["id", "team", "onSaved"])
      ]),
      _: 1
    }, 8, ["name"])
  ], 64))
}